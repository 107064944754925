import React, { useState, useEffect, useMemo } from "react";
import { useCss, k, a } from "kremling";
import { CpButton } from "canopy-styleguide!sofe";

const buttonWidth = 102;

export const NextSignature = ({
  documentSizes,
  goToPrevSigningLocations,
  goToSigningLocations,
  inReview,
  nextLocation,
  prevLocation,
  scrollContainer,
  scrollEnded,
  signingRequestIsValid,
}) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const scope = useCss(css);

  const leftStyle = useMemo(() => {
    //110 is the width of the buttons plus 4 pixels
    const pageWidth = documentSizes?.[nextLocation?.page || 0]?.width || 816;
    const leftPageEdge = pageWidth / 2 + (buttonWidth + 4);
    return { left: `calc(50vw - ${leftPageEdge}px)`, transition: "left 0.1s ease" };
  }, [JSON.stringify(documentSizes), nextLocation]);

  const buttonText = useMemo(() => {
    if (initialLoad) {
      return "Start";
    }
    if (signingRequestIsValid || inReview) {
      return "Next";
    }
    if (nextLocation && scrollEnded) {
      if (nextLocation.type === "signature") return "Sign";
      if (nextLocation.type === "initials") return "Initial";
      if (nextLocation.type === "text" && !nextLocation.hasBeenSigned) return "Add text";
    }
    return "Next";
  }, [nextLocation, scrollEnded, signingRequestIsValid, initialLoad]);

  useEffect(() => {
    if (initialLoad && nextLocation) setInitialLoad(false);
  }, [initialLoad, nextLocation]);

  return (
    (!signingRequestIsValid || inReview) && (
      <div {...scope} className="fixed-button-wrapper" style={leftStyle}>
        <CpButton
          disabled={initialLoad || !prevLocation}
          btnType="secondary"
          className="cp-mb-16 fixed-block-button"
          icon="caret-large-up"
          onClick={() => goToPrevSigningLocations(initialLoad)}
        >
          Back
        </CpButton>
        <div onClick={() => goToSigningLocations(initialLoad, scrollContainer)} className="fixed-sign-button">
          <CpButton
            block
            btnType="primary"
            icon={scrollEnded && !nextLocation?.hasBeenSigned && nextLocation ? undefined : "caret-large-down"}
          >
            {buttonText}
          </CpButton>
          <svg
            className={a("svgFlag").t("svgVisible", "svgHidden", scrollEnded && nextLocation)}
            height="32"
            viewBox="0 0 19 32"
            width="19"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17.7473 12.9986C19.082 14.7769 19.082 17.2231 17.7473 19.0014L9.49893 29.9911C6.61507 33.8334 0.5 31.7938 0.5 26.9896V5.01035C0.5 0.20619 6.61507 -1.83337 9.49893 2.00894L17.7473 12.9986Z" />
          </svg>
        </div>
      </div>
    )
  );
};

const css = k`
  .svgFlag {
    fill: var(--cp-color-app-primary); 
    margin-left: -8px
  }

  .svgHidden {
    height: 0;
  }

  .svgVisible {
    height: 32px;
    transition: all 0.075s ease-out;
  }

  .fixed-block-button {
    width: 91%;
  }

  .fixed-button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    top: calc(100vh - 53.5%);
    position: fixed;
    width: ${buttonWidth}px;
  }

  .fixed-sign-button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;
