import React, { useState, useMemo } from "react";
import { featureEnabled } from "feature-toggles!sofe";
import { CpSelectSingle, CpButton, useDebounce } from "canopy-styleguide!sofe";
import { groupDataForSelect } from "../../../signing-modal.helper";
import { templateTypes } from "../../../constants";

export const SelectTemplateDropdown = ({
  applyTemplate,
  esignTemplates,
  hasEsignImprovementsBeta,
  isTemplate,
  setSearchTerm,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const debounceSearch = useDebounce((value) => {
    setSearchTerm(value);
  }, 300);

  const searchOnChange = (value) => {
    setSearchValue(value);
    debounceSearch(value.trim());
  };

  const groupTemplates = useMemo(() => {
    const groups = groupDataForSelect(templateTypes);

    return groups
      .map((group) => {
        return {
          ...group,
          data: esignTemplates?.filter((template) => {
            const hasTextFields = template?.signing_locations?.some((location) => location.type === "text");
            const matchesGroup = template?.template_type === group.id;
            return (
              matchesGroup &&
              ((featureEnabled("toggle_files_esign_improvements") && hasEsignImprovementsBeta) || !hasTextFields)
            );
          }),
        };
      })
      .filter((group) => group?.data?.length);
  }, [esignTemplates, searchValue]);

  return (
    <div style={{ height: "48px", borderTop: "1px solid var(--cp-color-app-border)" }} className="cp-flex-center">
      <CpSelectSingle
        data={groupTemplates}
        isGroupData
        contentWidth={308}
        insertSearch
        searchValue={searchValue}
        searchOnChange={searchOnChange}
        searchFilter={CpSelectSingle.filterAlpha(searchValue)}
        transformData={({ template_name, id }) => ({ name: template_name, id: id })}
        renderTrigger={({ toggle }) => (
          <CpButton className="cp-ml-16" onClick={toggle} btnType="flat" icon="organize-template">
            Templates
          </CpButton>
        )}
        placeholder="Select Template"
        onChange={(template) => applyTemplate(template)}
      />
      {isTemplate && (
        <div className="cp-ml-16 cp-color-app-secondary-text" style={{ fontStyle: "italic" }}>
          Template applied
        </div>
      )}
    </div>
  );
};
