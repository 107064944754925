import React from "react";
import { CpDropdown, CpButton, CpTooltip } from "canopy-styleguide!sofe";
import { useCss, a, k } from "kremling";
import { typeIconMatch } from "../../constants";
import { FieldTypeButton } from "./field-type-button.component";

export const SignerToolbarButtons = ({
  buttonsContainerRef,
  duplicateSigningField,
  deleteField,
  signatureElements,
  signerFieldProps,
  signingObject,
  updateSigningObjectFieldType,
}) => {
  const scope = useCss(css);
  const fieldIcon = typeIconMatch[signingObject.type];

  const changeFieldType = (newType) => {
    updateSigningObjectFieldType(signingObject.id, newType);
  };

  return (
    <div className="cp-flex-center" ref={buttonsContainerRef}>
      <div {...scope} className="toolbar-divider" />
      <CpDropdown
        renderTrigger={({ toggle }) => (
          <CpTooltip text="Field type">
            <CpButton
              icon={fieldIcon}
              aria-label="Change Field"
              className="cp-mh-4"
              btnType="icon-dark"
              onClick={(e) => {
                if (signingObject.value) return;
                toggle(e);
              }}
            >
              Options
            </CpButton>
          </CpTooltip>
        )}
        renderContent={() => (
          <div className="cp-select-list">
            {signatureElements.map((fieldType) => (
              <FieldTypeButton
                key={fieldType.type}
                changeFieldType={changeFieldType}
                currentType={signerFieldProps.type}
                fieldType={fieldType}
              />
            ))}
          </div>
        )}
      />
      <CpTooltip text="Duplicate">
        <CpButton
          icon="crud-duplicate"
          aria-label="Duplicate"
          onClick={() => duplicateSigningField(signingObject)}
          btnType="icon-dark"
          className="flex-shrink-0 cp-mh-4"
        />
      </CpTooltip>
      <CpTooltip text="Delete">
        <CpButton
          icon="crud-trash-large"
          aria-label="Delete"
          onClick={() => deleteField()}
          btnType="icon-dark"
          className="flex-shrink-0 cp-mh-4"
        />
      </CpTooltip>
    </div>
  );
};

const css = k`
  .toolbar-divider {
    width: 1px;
    height: 20px;
    background-color: var(--cp-color-nav-active-border);
    margin: 0 16px;
  }
`;
