import React, { useState, useEffect, useRef, useContext } from "react";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import { useCss, k, a, m } from "kremling";
import { ResizableFieldDimensions, minFieldWidthAllowed, minFieldHeightAllowed } from "../../constants";
import { userMatchesSigningLocation } from "../../signing-modal.helper";

import { SigningContext } from "../../signing-context";
import { getDefaultFieldWidth } from "src/signing-resource.helper";
import { ResizeHandle } from "./resizable-field-handle.component";
import { CompletedField } from "../completed-field.component";
import { DraggableResizableFieldHeader } from "./draggable-resizable-field-header.component";
import { DraggableResizableTextField } from "./draggable-resizable-text-field.component";
import { PlaceholderText } from "./draggable-resizable-field-placeholder.component";

export const DraggableResizableField = ({
  documentRef,
  dropdownRef,
  fieldRef,
  resizeHandleRef,
  checkFieldSize,
  checkToolbarPosition,
  context,
  handleShowToolbar,
  hasBeenSigned,
  isDateObject,
  isSignatureObject,
  isTemplateMode,
  isTextField,
  openSignatureEntryModal,
  showFieldHeader,
  signerFieldProps,
  signingLocationsAreDraggable,
  signingObject,
  updateSigningObject,
}) => {
  const scope = useCss(css);
  const completedFieldRef = useRef(null);
  const textareaRef = useRef(null);
  const previousType = useRef(signingObject.type);

  const defaultWidth = getDefaultFieldWidth(signingObject.type);
  const { activeSignerFieldId, setActiveSignerFieldId } = useContext(SigningContext);

  // default starting height or width of the field
  const [size, setSize] = useState({
    width: signingObject?.width || defaultWidth,
    height: signingObject?.height || ResizableFieldDimensions.DEFAULT_HEIGHT,
  });

  const setRoundedSize = (dimensions) => {
    setSize({
      width: Math.round(dimensions.width),
      height: Math.round(dimensions.height),
    });
  };

  const updateRoundedSigningObject = (object) => {
    const rounded = {
      ...object,
      width: Math.round(object.width),
      height: Math.round(object.height),
    };
    updateSigningObject(rounded);
  };

  useEffect(() => {
    if (size.width && size.height) {
      // call checkToolbarPosition to update the toolbar position if size changes
      requestAnimationFrame(() => {
        const updatedFieldRect = fieldRef.current.getBoundingClientRect();
        checkToolbarPosition(updatedFieldRect);
      });
    }
  }, [size.width, size.height]);

  const resizableFieldFontSize = 12;

  const [maxConstraints, setMaxConstraints] = useState([400, 400]); // Default fallback height/width in case we can't get the page
  const [minConstraints, setMinConstraints] = useState([minFieldWidthAllowed, minFieldHeightAllowed]); // minimum height/width allowed
  const [textFieldValue, setTextFieldValue] = useState(signingObject?.value || "");

  const [showToolbar, setShowToolbar] = useState(false); // used to show toolbar once component is mounted
  const [fieldIsActive, setFieldIsActive] = useState(false);
  const fieldHasBeenSigned = hasBeenSigned || !!signerFieldProps?.signedLocation;
  const signingObjectId = signingObject.esigning_location_id || signingObject.id;

  const isTeamMember =
    signingObject?.isTeamMember ||
    signingObject?.signatory_user_role === "TeamMember" ||
    signingObject?.role === "TeamMember";

  const forCurrentUser = context?.loggedInUser
    ? userMatchesSigningLocation(signingObject, context.loggedInUser)
    : false;

  useEffect(() => {
    // Find the page container element
    const fieldElement = documentRef.current;
    if (fieldElement) {
      // get page size from page container so max field size is limited by page size
      const pageElement = fieldElement.closest('[id^="pageView"]');
      if (pageElement) {
        const pageWidth = pageElement.clientWidth;
        const pageHeight = pageElement.clientHeight;
        setMaxConstraints([pageWidth, pageHeight]);
      }
    }
  }, [documentRef]);

  useEffect(() => {
    // set to true to show toolbar once component is mounted
    setShowToolbar(true);
    // initialize field size to signing object for templates
    if (!signingObject.width || !signingObject.height) {
      const initialFieldObject = {
        ...signingObject,
        width: signingObject?.width || defaultWidth,
        height: signingObject?.height || ResizableFieldDimensions.DEFAULT_HEIGHT,
      };
      updateRoundedSigningObject(initialFieldObject);
    }
  }, []);

  useEffect(() => {
    // show toolbar when new field is dropped on page
    if (showToolbar && typeof handleShowToolbar === "function" && activeSignerFieldId === signingObjectId) {
      const fieldElement = fieldRef.current?.querySelector("[data-field-container]");
      if (fieldElement) {
        handleShowToolbar({ target: fieldElement });
        checkFieldSize.current = true;
      }
      setShowToolbar(false);
    }
  }, [showToolbar, handleShowToolbar]);

  const handleResize = (e, { size: newSize }) => {
    //TODO move below into useLayoutEffect?
    if (isTextField && signingObject.value) {
      // create a temp textarea to get the content height
      const tempTextArea = document.createElement("textarea");
      tempTextArea.style.cssText = `
        position: absolute;
        visibility: hidden;
        height: auto;
        width: ${Math.round(newSize.width)}px; 
        font: ${window.getComputedStyle(textareaRef.current).font};
        line-height: ${window.getComputedStyle(textareaRef.current).lineHeight};
        white-space: pre-line;
        overflow-wrap: break-word;
        padding: 0;
        border: 0;
        margin: 0;
      `;
      tempTextArea.value = textareaRef.current.value;

      document.body.appendChild(tempTextArea);
      const contentHeight = tempTextArea.scrollHeight;
      document.body.removeChild(tempTextArea);

      const newWidth = Math.max(minFieldWidthAllowed, textareaRef.current.scrollWidth);
      const newHeight = Math.max(minFieldHeightAllowed, contentHeight);
      // set constraints to limit resizing of text fields to their content
      setMinConstraints([newWidth, newHeight]);

      setRoundedSize({
        width: Math.max(newSize.width, newWidth),
        height: Math.max(newSize.height, newHeight),
      });
    } else {
      setRoundedSize({
        width: newSize.width,
        height: newSize.height,
      });
    }
  };

  const handleResizeStop = (e, { size: newSize }) => {
    //TODO move below into useLayoutEffect?
    let minWidth = minFieldWidthAllowed;
    let minHeight = minFieldHeightAllowed;

    if (completedFieldRef.current) {
      const contentWidth = completedFieldRef.current.scrollWidth;
      const contentHeight = completedFieldRef.current.scrollHeight;

      // Update minWidth and minHeight based on the completed field's dimensions
      minWidth = Math.max(minFieldWidthAllowed, contentWidth);
      minHeight = Math.max(minFieldHeightAllowed, contentHeight);
    }

    setRoundedSize({
      width: Math.max(newSize.width, minWidth),
      height: Math.max(newSize.height, minHeight),
    });

    if (isTextField && signingObject.value) setMinConstraints([minWidth, minHeight]);

    const updatedObject = {
      ...signingObject,
      width: Math.max(newSize.width, minWidth),
      height: Math.max(newSize.height, minHeight),
    };
    updateRoundedSigningObject(updatedObject);
    // if the field is resized then we want the useEffect to run to check the page edges
    // so text fields are kept on the page and do not run off the top or right edge
    checkFieldSize.current = true;
  };

  useEffect(() => {
    if (completedFieldRef.current && isSignatureObject) {
      // when placing signature in completed field, make sure width is not cut off
      // enlarge the field if needed to fit the content but only up to 400px
      const contentWidth = completedFieldRef.current.scrollWidth;
      if (signingObject.width < contentWidth) {
        setRoundedSize({
          width: Math.min(Math.max(contentWidth + 4, signingObject.width), 400),
          height: signingObject.height,
        });
      }
    }
  }, [signingObject.value, signingObject.completed_at, signingObject.hasBeenSigned]);

  useEffect(() => {
    if (previousType.current && previousType.current !== signingObject.type) {
      setRoundedSize({
        width: defaultWidth,
        height: ResizableFieldDimensions.DEFAULT_HEIGHT,
      });
      updateRoundedSigningObject({
        ...signingObject,
        width: defaultWidth,
        height: ResizableFieldDimensions.DEFAULT_HEIGHT,
      });
    }
    // Update ref for next comparison
    previousType.current = signingObject.type;
  }, [signingObject.type]);

  const handleClickShowToolbar = (e) => {
    e.stopPropagation();
    if (signingObject.signed) return;
    setActiveSignerFieldId(signingObjectId);
    handleShowToolbar(e);
  };

  useEffect(() => {
    // if the field was not resized or dragged then we don't need to check the page edges
    // when a template is applied do not adjust the field size, only if the field is dragged or resized
    // (the measurement in relativeFieldTopEdge below is different when applying a template and unnecessarily resizes fields)
    if (!checkFieldSize.current) return;
    // check if the field is on the current page and resize the field if
    // it is a text field and goes off the right edge of the page
    const documentId = documentRef.current?.id;
    const currentPageIndex = parseInt(documentId.split("pageView")[1]);
    const fieldOnCurrentPage = currentPageIndex === signingObject?.page;
    if (!isTextField || !fieldOnCurrentPage || !documentRef.current || !signingLocationsAreDraggable) return;

    const pageRect = documentRef.current.getBoundingClientRect();
    const fieldRect = fieldRef.current.getBoundingClientRect();
    let newWidth = size.width;
    let newHeight = size.height;
    let needsUpdate = false;

    const relativeFieldRightEdge = fieldRect.right - pageRect.left;
    if (relativeFieldRightEdge > pageRect.width) {
      const difference = relativeFieldRightEdge - pageRect.width;
      // resize field to keep on page but not below minFieldWidthAllowed
      newWidth = Math.max(newWidth - difference, minFieldWidthAllowed);
      needsUpdate = true;
    }

    const relativeFieldTopEdge = fieldRect.top - pageRect.top;
    const checkFields = isTemplateMode ? checkFieldSize.current === true : true;
    if (checkFields && relativeFieldTopEdge < 0) {
      const difference = Math.abs(relativeFieldTopEdge);
      // resize field to keep on page but not below minFieldHeightAllowed
      newHeight = Math.max(size.height - difference, minFieldHeightAllowed);
      needsUpdate = true;
    }

    if (needsUpdate) {
      setRoundedSize({ width: newWidth, height: newHeight });
      const updatedObject = {
        ...signingObject,
        width: newWidth,
        height: newHeight,
      };
      updateRoundedSigningObject(updatedObject);
    }
    // reset checkFieldSize to false after the field has been resized
    checkFieldSize.current = false;
  }, [signingObject]);

  const handleClick = (e) => {
    e.stopPropagation();
    if (signingObject.signed) return;
    !isDateObject && setActiveSignerFieldId(signingObjectId);

    // open signature entry modal if field is active and toolbar open
    if ((fieldIsActive || activeSignerFieldId) && forCurrentUser && !isDateObject && !fieldHasBeenSigned) {
      openSignatureEntryModal();
    } else {
      handleShowToolbar(e);
    }
  };

  const showActiveSignerFieldExtras =
    activeSignerFieldId === signingObjectId && !signingObject.signed && !signingLocationsAreDraggable;

  return (
    <div
      {...scope}
      ref={fieldRef}
      onClick={() => setActiveSignerFieldId(signingObjectId)}
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
      }}
    >
      {((showFieldHeader && signingLocationsAreDraggable) || showActiveSignerFieldExtras) && (
        <DraggableResizableFieldHeader
          handleClickShowToolbar={handleClickShowToolbar}
          signerFieldProps={signerFieldProps}
          size={size}
          isTeamMember={isTeamMember}
        />
      )}
      {
        <ResizableBox
          data-field-container
          width={size.width}
          height={size.height}
          onResize={handleResize}
          onResizeStop={handleResizeStop}
          minConstraints={minConstraints}
          maxConstraints={maxConstraints}
          resizeHandles={signerFieldProps?.allowResize ? ["ne"] : []}
          handle={
            signerFieldProps?.allowResize ? <ResizeHandle signingObject={signingObject} ref={resizeHandleRef} /> : null
          }
        >
          <div // border around the field
            className={a("custom-resizable-box-border").m("custom-resizable-box-border-teamMember", isTeamMember)}
            style={{ height: `${size.height}px` }}
            onClick={handleClick}
          >
            <div // transparent background
              className={a("custom-resizable-box-background").m(
                "custom-resizable-box-background-teamMember",
                isTeamMember
              )}
            />

            {isTextField ? (
              <DraggableResizableTextField
                completedFieldRef={completedFieldRef}
                context={context}
                dropdownRef={dropdownRef}
                forCurrentUser={forCurrentUser}
                handleClickShowToolbar={handleClickShowToolbar}
                isTeamMember={isTeamMember}
                isTextField={isTextField}
                resizableFieldFontSize={resizableFieldFontSize}
                setFieldIsActive={setFieldIsActive}
                setTextFieldValue={setTextFieldValue}
                showActiveSignerFieldExtras={showActiveSignerFieldExtras}
                signerFieldProps={signerFieldProps}
                signingLocationsAreDraggable={signingLocationsAreDraggable}
                signingObject={signingObject}
                size={size}
                textareaRef={textareaRef}
                textFieldValue={textFieldValue}
                updateSigningObject={updateSigningObject}
              />
            ) : (
              <PlaceholderText
                context={context}
                dropdownRef={dropdownRef}
                fieldHasBeenSigned={fieldHasBeenSigned}
                forCurrentUser={forCurrentUser}
                handleClick={handleClick}
                isDateObject={isDateObject}
                isSignatureObject={isSignatureObject}
                isTeamMember={isTeamMember}
                isTextField={isTextField}
                setFieldIsActive={setFieldIsActive}
                showActiveSignerFieldExtras={showActiveSignerFieldExtras}
                signingLocationsAreDraggable={signingLocationsAreDraggable}
                textFieldValue={textFieldValue}
              >
                {fieldHasBeenSigned && (
                  <CompletedField
                    {...signerFieldProps}
                    context={context}
                    forCurrentUser={forCurrentUser}
                    signingObject={signingObject}
                    openSignatureEntryModal={openSignatureEntryModal}
                    resizableFieldFontSize={resizableFieldFontSize}
                    completedFieldRef={completedFieldRef}
                    handleClickShowToolbar={handleClickShowToolbar}
                  />
                )}
              </PlaceholderText>
            )}
          </div>
        </ResizableBox>
      }
    </div>
  );
};

DraggableResizableField.displayName = "DraggableResizableField";

const css = k`
  .custom-resizable-box-border {
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--cp-color-default-badge-bg);
  }

  .custom-resizable-box-border-teamMember {
    border: 1px solid var(--cp-color-pill-team-text);
  }

  .custom-resizable-box-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--cp-color-pill-bg);
    opacity: 0.5;
    pointer-events: none;
    z-index: 1;
  }

  .custom-resizable-box-background-teamMember {
    background-color: var(--cp-color-pill-team-bg);
  }
`;
