import React, { useContext, useState, useEffect, useMemo } from "react";
import { CpButton, CpDropdown, CpIcon, modalService } from "canopy-styleguide!sofe";
import { SigningContext } from "../../../signing-context";
import styles from "../../esign-modal.styles.css";
import { CustomSignerModal } from "./custom-signer.modal.component";
import { useCreateOrEditCustomSigner } from "../../../custom-signer.hooks";
import { uniq } from "lodash";
import { SignerTypeModal } from "./signer-type.modal.component";
import { SignerWell } from "./signer-wells.component";
import { SelectSignerDropdown } from "./select-signer-dropdown.component";
import { successToast } from "toast-service!sofe";
import { deleteSignerType } from "../../../signing.resource";
import { handleError } from "src/handle-error.helper";
import { SignerConfirmDeleteModal } from "./signer-delete-confirm.component";

export const SignersTab = ({
  primaryClientClientPortalUsers,
  clientId,
  isTemplateMode,
  isTemplate,
  assignSignerTypes,
  signingLocations,
  shouldShowSignatureEntryModal,
  setSigningLocations,
  addSigningLocations,
}) => {
  const {
    signersContext,
    signerTypesContext,
    setScrollTo,
    selectedSignerIds,
    setSelectedSignerIds,
    getSelectedSigners,
    setSignerTypesContext,
    setHideSignerToolbar,
  } = useContext(SigningContext);

  const [showCustomSigner, setShowCustomSigner] = useState(false);
  const [signerToEdit, setSignerToEdit] = useState(null);
  const selectedSigners = getSelectedSigners();

  const signerTypeHasLocation = (signerType) => {
    return signingLocations.find((location) => location.signer_type_id === signerType.id);
  };
  const addSingleSigner = (signer) => {
    setSelectedSignerIds(uniq([...selectedSignerIds, signer.id]));
  };

  const { addOrEditUser, deleteUser, setUser } = useCreateOrEditCustomSigner({
    clientId,
    afterSave: addSingleSigner,
  });

  useEffect(() => {
    setUser(signerToEdit);
  }, [signerToEdit]);

  const removeSigner = (signer) => {
    setSelectedSignerIds(selectedSignerIds.filter((id) => id !== signer.id));
  };

  const editSigner = (signer) => {
    setSignerToEdit(signer);
    setShowCustomSigner(true);
  };

  const hardDeleteSignerType = async (signerType) => {
    try {
      await deleteSignerType(signerType.id).toPromise();
      setSignerTypesContext(signerTypesContext.filter((type) => type.id !== signerType.id));
      successToast(`Signer type "${signerType.name}" was deleted.`);
    } catch (err) {
      handleError(err);
    }
  };

  const removeSignerSelection = (signer) => {
    const role = signerTypesContext.find((type) => type?.assignedTo?.id === signer?.id);
    removeSigner(signer);
    const removedSignerLocations = [...signingLocations].filter((location) => {
      if (location?.signer_type_id) return false;
      if (signer?.user_id) return location.signer_id === signer.id && location.signatory_user_id === signer.user_id;
      if (!location.signer_id) return location.signatory_user_id === signer.id;
      return false;
    });
    const filteredSigningLocations = signingLocations.filter((location) => {
      if (location?.signer_type_id) return true;
      if (signer?.user_id && location?.signer_id) return location.signer_id !== signer.id;
      if (!location.signer_id) return location.signatory_user_id !== signer.id;
      return true;
    });

    role && assignSignerTypes?.({ user_id: null, id: null }, role, filteredSigningLocations);
    !role && assignSignerTypes?.(signer, role, filteredSigningLocations);

    const truncatedName = signer.name.length > 24 ? `${signer.name.substring(0, 24)}...` : signer.name;
    if (removedSignerLocations.length > 0) {
      successToast({
        message: `Signer "${truncatedName}" was removed.`,
        actionText: "Undo",
        actionCallback: () => {
          addSigningLocations(removedSignerLocations);
          addSingleSigner(signer);
        },
      });
    }
  };

  const scrollToLocation = (id) => {
    const location = signingLocations
      .sort((a, b) => a.y - b.y)
      .sort((a, b) => a.page - b.page)
      .find((location) => location.signer_type_id === id && location.type !== "date");
    location.id && setScrollTo(location);
  };

  const chickenSwitch = (signer) => {
    modalService.render(SignerConfirmDeleteModal).then((res) => {
      if (res === "delete") {
        if (!isTemplateMode) {
          removeSignerSelection(signer);
          return deleteUser(signer?.id, signer);
        } else {
          return hardDeleteSignerType(signer);
        }
      }
      if (res === "remove") return removeSignerSelection(signer);
    });
  };

  const markFields = (signerId) => {
    const signingObjects = [...signingLocations];
    const signLocations = signingObjects.map((item) => {
      if (item.signer_id === signerId || item.signatory_user_id === signerId) {
        return { ...item, hasBeenSigned: true };
      }
      return item;
    });
    setSigningLocations(signLocations);
  };

  const combinedSigners = useMemo(() => {
    const filteredTypes = signerTypesContext.filter(
      (type) => isTemplate && !type.assignedTo && signerTypeHasLocation(type)
    );
    return [...filteredTypes, ...selectedSigners].sort((a, b) => {
      const aId = a.assignedTo?.id || a.id;
      const bId = b.assignedTo?.id || b.id;
      if ("email" in a && !a.assignedTo) return 1;
      if ("email" in b && !b.assignedTo) return -1;
      return aId.localeCompare(bId);
    });
  }, [signerTypesContext, selectedSigners]);

  return (
    <>
      <SelectSignerDropdown
        isTemplateMode={isTemplateMode}
        assignSignerTypes={assignSignerTypes}
        isMulti
        onClose={() => {
          setShowCustomSigner(true);
          setSignerToEdit(null);
        }}
        renderTrigger={({ toggle }) => (
          <CpButton icon="add-small" btnType="flat" onClick={toggle}>
            Add signers
          </CpButton>
        )}
      />
      {combinedSigners.map((signer) =>
        // preset on the signer confirms its a signer_type and not a signer
        "preset" in signer && isTemplate ? (
          <SignerWell
            key={signer.id}
            signer={signer}
            actionButton={
              <SelectSignerDropdown
                isTemplateMode={isTemplateMode}
                assignSignerTypes={assignSignerTypes}
                signerType={signer}
                renderTrigger={({ toggle }) => (
                  <CpButton
                    btnType="secondary"
                    onClick={(e) => {
                      scrollToLocation(signer.id);
                      toggle(e);
                    }}
                    aria-label="Assign role"
                  >
                    Assign
                  </CpButton>
                )}
              />
            }
          />
        ) : (
          <SignerWell
            key={signer.id}
            assignedType={signer.assignedTo}
            signer={signer}
            actionButton={
              <CpDropdown
                renderTrigger={({ toggle }) => (
                  <CpButton className="shrink-0" icon="misc-kabob" onClick={toggle} aria-label="Settings" />
                )}
                contentWidth="auto"
                renderContent={() => (
                  <div className="cp-select-list">
                    {signer.isSelf && signingLocations.find((location) => location.signatory_user_id === signer?.id) && (
                      <button
                        className={styles.signersTabDropdown}
                        onClick={() => shouldShowSignatureEntryModal(true, "signature", () => markFields(signer.id))}
                      >
                        <CpIcon className="cp-select-list__icon-left" name="communication-signature-pen" />
                        Sign document
                      </button>
                    )}
                    {((isTemplateMode && !signer?.preset) || signer?.canEdit) && (
                      <button className={styles.signersTabDropdown} onClick={() => editSigner(signer)}>
                        <CpIcon className="cp-select-list__icon-left" name="crud-pencil" />
                        Edit {!isTemplateMode ? "custom signer" : "signer"}
                      </button>
                    )}
                    <button className={styles.signersTabDropdown} onClick={() => removeSignerSelection(signer)}>
                      <CpIcon className="cp-select-list__icon-left" name="close-circle-open" />
                      {!isTemplateMode && signer.assignedTo ? "Unassign" : " Remove signer"}
                    </button>
                    {((isTemplateMode && !signer?.preset) || signer?.canEdit) && (
                      <button className={styles.signersTabDropdown} onClick={() => chickenSwitch(signer)}>
                        <CpIcon className="cp-select-list__icon-left" name="crud-trash-large" />
                        Delete {!isTemplateMode ? "custom signer" : "signer"}
                      </button>
                    )}
                  </div>
                )}
              />
            }
          />
        )
      )}
      {showCustomSigner && isTemplateMode && (
        <SignerTypeModal
          signer={signerToEdit}
          show={showCustomSigner}
          addSingleSigner={addSingleSigner}
          close={() => {
            setShowCustomSigner(false);
          }}
        />
      )}
      {showCustomSigner && !isTemplateMode && (
        <CustomSignerModal
          allUsers={signersContext}
          user={signerToEdit}
          setUser={setSignerToEdit}
          show={showCustomSigner}
          primaryClientClientPortalUsers={primaryClientClientPortalUsers}
          clientId={clientId}
          addOrEditUser={addOrEditUser}
          deleteUser={deleteUser}
          close={() => {
            setShowCustomSigner(false);
          }}
          afterClose={() => setSignerToEdit(null)}
        />
      )}
    </>
  );
};
