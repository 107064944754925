import React, { useRef, useEffect, useMemo, useContext, useState } from "react";
import { useCss, a, k } from "kremling";
import { CpContextDropdown } from "canopy-styleguide!sofe";
import { typeIconMatch, signingFieldTypes, signatureElements, signatureElementsBeta } from "../../constants";
import { convertDateToString, userMatchesSigningLocation } from "../../signing-modal.helper";
import { SigningContext } from "../../signing-context";
import { DraggableResizableField } from "../draggable-resizable-field-components/draggable-resizable-field.component";
import { SignerName } from "./signer-name.component";
import { SignerToolbarButtons } from "./signer-toolbar-buttons.component";

const textFields = {
  date: "Date",
  initial: "Initial",
  signature: "Recipient Signature",
  text: "Text Field",
  you: "You",
  yourInitial: "Your Initial",
  yourSignature: "Your Signature",
  yourText: "Your Text",
};

export function SignerFieldToolbar({
  allUsers,
  clientCollaborators,
  context,
  deleteSigningField,
  documentRef,
  duplicateSigningField,
  hasEsignImprovementsBeta,
  isActive,
  isDragging, // from DragWrapper
  isTemplateMode,
  markFieldAsSigned,
  handleActiveToolbarOpen,
  onDropdownRef,
  openSignatureEntryModal,
  resizeHandleRef, // from DragWrapper
  setSigner,
  showSignerDropdown,
  signingLocationsAreDraggable,
  signingObject,
  updateSigningObject,
  updateSigningObjectFieldType,
}) {
  const scope = useCss(css);

  const dropdownRef = useRef();
  const fieldRef = useRef();
  const toolbarRef = useRef();
  const buttonsContainerRef = useRef();
  const draggableRef = useRef();
  const scrollRef = useRef();
  const checkFieldSize = useRef(false);

  const signDate = signingObject.completed_at;
  const forCurrentUser = userMatchesSigningLocation(signingObject, context.loggedInUser);
  const client = allUsers.find((user) => userMatchesSigningLocation(signingObject, user));
  //TODO: do we need the clientCollaborators[signingObject.signatory_user_id] check?
  const clientName = client?.name || clientCollaborators[signingObject.signatory_user_id];
  const isSignatureObject = signingObject.type === signingFieldTypes.SIGNATURE;
  const isInitialObject = signingObject.type === signingFieldTypes.INITIAL;
  const isDateObject = signingObject.type === signingFieldTypes.DATE;

  const isTextField = signingObject.type === signingFieldTypes.TEXT;
  const hasBeenSigned = signingObject.hasBeenSigned || signingObject.signed;

  const practitionerSignature = forCurrentUser && signingObject.isTeamMember;
  const displayValue = signingObject.value && signDate && (isDateObject || hasBeenSigned || practitionerSignature);

  const { scrollTo, setScrollTo, signerTypesContext, activeSignerFieldId, setActiveSignerFieldId, hideSignerToolbar } =
    useContext(SigningContext);

  const [showFieldHeader, setShowFieldHeader] = useState(false);
  // used to determine the active field on the signer view (client portal or secure link)
  const signerViewActiveField =
    !signingLocationsAreDraggable && activeSignerFieldId === signingObject.esigning_location_id;

  // Position toolbar above the resized height of the field with a small gap
  const toolbarOffset = signingObject.height + 4;

  const openToolbar = () => {
    if (!dropdownRef.current) return;
    setShowFieldHeader(false);
    // Initial position
    dropdownRef.current.open({
      top: -toolbarOffset,
      left: 0,
    });
  };

  const checkToolbarPosition = (fieldRect) => {
    if (!fieldRect) return;

    requestAnimationFrame(() => {
      const dropdownContent = toolbarRef?.current;
      const dropdownDimensions = dropdownContent.getBoundingClientRect();
      const rightSideButtonsWidth = buttonsContainerRef.current?.offsetWidth || 0;
      if (!dropdownContent || !rightSideButtonsWidth) return;
      const leftPanelWidth = 372;
      const safetyMargin = 16;

      const rect = documentRef.current?.getBoundingClientRect();
      const rightSideOfPage = rect?.right;
      // First check if we would overflow off the right side of the screen
      const wouldOverflowRight = fieldRect.left + dropdownDimensions.width > rightSideOfPage;
      // Calculate max name width based on whether we need to shift
      let maxNameWidth;
      if (wouldOverflowRight) {
        // If shifting, use space from left panel to field position
        maxNameWidth = fieldRect.left - leftPanelWidth - safetyMargin;
      } else {
        // If not shifting, use space from field to right edge of screen
        maxNameWidth = window.innerWidth - fieldRect.right - rightSideButtonsWidth - safetyMargin + 86;
      }

      // Ensure minimum width of 100px and set the CSS variable
      maxNameWidth = Math.max(100, maxNameWidth);
      dropdownContent.style.setProperty("--max-name-width-toolbar", `${maxNameWidth}px`);

      // Get the new toolbar width after name is shortened (if needed)
      const toolbarWidth = dropdownContent.getBoundingClientRect().width;

      let leftOffset = 0;
      if (wouldOverflowRight) {
        // Align right edges of field and toolbar
        leftOffset = fieldRect.width - toolbarWidth;
      }

      // Reposition the dropdown
      dropdownRef.current.open({
        top: -toolbarOffset,
        left: leftOffset,
      });
    });
  };

  useEffect(() => {
    const currentRef = dropdownRef.current;

    if (onDropdownRef && currentRef) {
      onDropdownRef(signingObject.id, dropdownRef);

      // Cleanup only when unmounting or ref actually changes
      return () => {
        if (dropdownRef.current === currentRef) {
          onDropdownRef(signingObject.id, null);
        }
      };
    }
  }, [onDropdownRef, signingObject.id]);

  const handleShowToolbar = (e) => {
    if (!signingLocationsAreDraggable || hideSignerToolbar) return;
    if (isActive && dropdownRef.current?.isOpen) return;

    handleActiveToolbarOpen(signingObject.id);
    if (dropdownRef.current) {
      openToolbar();
      if (e?.target) {
        const fieldElement =
          e.target.closest("[data-field-container]") ||
          e.target.closest(".custom-resizable-box-header")?.nextElementSibling;
        if (fieldElement) {
          checkToolbarPosition(fieldElement.getBoundingClientRect());
        }
      }
    }
  };

  useEffect(() => {
    // if the field is placed on the page or moved at all then set checkFieldSize to true
    // to trigger the size check in the useEffect in the resizable field component
    if (isDragging) checkFieldSize.current = true;
    // close the toolbar when dragging a field
    if (isDragging && dropdownRef.current) {
      dropdownRef.current.close();
    }
  }, [isDragging]);

  useEffect(() => {
    // If toolbar is open and height changes, reposition it
    if (dropdownRef.current?.isOpen) {
      requestAnimationFrame(() => {
        const fieldElement = scrollRef.current?.querySelector("[data-field-container]");
        if (fieldElement) {
          const fieldRect = fieldElement.getBoundingClientRect();
          checkToolbarPosition(fieldRect);
        }
      });
    }
  }, [signingObject.height]);

  useEffect(() => {
    // Close dropdown when field becomes inactive
    if (!isActive && dropdownRef.current) {
      dropdownRef.current.close();
    }
  }, [isActive]);

  const deleteField = () => {
    deleteSigningField(signingObject.id);
  };

  useEffect(() => {
    if (showSignerDropdown?.id === signingObject.id) {
      draggableRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [showSignerDropdown]);

  const renderText = useMemo(() => {
    if (signingObject?.signer_type_name && !signingObject?.signatory_user_id) return signingObject.signer_type_name;
    if (displayValue) {
      if (isTextField) {
        if (forCurrentUser) return textFields.yourText;
        return textFields.text;
      }
      return isDateObject ? convertDateToString(signDate) : signingObject.value;
    }
    if (forCurrentUser && signingLocationsAreDraggable) return textFields.you;
    if (forCurrentUser && !signingLocationsAreDraggable && isSignatureObject) return textFields.yourSignature;
    if (forCurrentUser && !signingLocationsAreDraggable && isInitialObject) return textFields.yourInitial;

    if (!forCurrentUser && clientName) return clientName;
    if (signingObject.signer_type?.name) return signingObject.signer_type.name;
    if (isSignatureObject) return textFields.signature;
    if (isInitialObject) return textFields.initial;
    if (isTextField) return textFields.text;
    return textFields.date;
  }, [
    signingObject.value,
    signingObject.signer_type_id,
    signingObject.signatory_user_id,
    signDate,
    clientName,
    hasBeenSigned,
  ]);

  useEffect(() => {
    if (scrollTo?.id === signingObject?.id) {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      setScrollTo(null);
      setActiveSignerFieldId(signingObject.esigning_location_id);
    }
  }, [scrollTo]);

  const signerTypeForObject =
    signingObject?.signer_type_id && signerTypesContext?.find((type) => type?.id === signingObject?.signer_type_id);

  const signerFieldProps = {
    allowResize:
      isTextField &&
      (signingLocationsAreDraggable ||
        (signerViewActiveField && signingObject.type === signingFieldTypes.SIGNATURE && hasBeenSigned)),
    dragHandle: signingLocationsAreDraggable,
    icon: !displayValue && typeIconMatch[signingObject?.type],
    teamMember:
      signerTypeForObject?.user_role === "TeamMember" ||
      signingObject?.isTeamMember ||
      signingObject?.signatory_user_role === "TeamMember",
    text: renderText,
    onRemove: signingLocationsAreDraggable && deleteField,
    font: (displayValue || hasBeenSigned) && !isDateObject && !isTextField && signingObject?.font,
    type: signingObject?.type,
    staticLocation: signingObject?.esigning_location_id && signingObject.signed && !signingLocationsAreDraggable,
    signedLocation: (displayValue || hasBeenSigned) && signingObject?.value && signingObject?.completed_at,
  };

  return (
    <div ref={scrollRef}>
      <DraggableResizableField
        context={context}
        checkFieldSize={checkFieldSize}
        checkToolbarPosition={checkToolbarPosition}
        documentRef={documentRef}
        dropdownRef={dropdownRef}
        handleShowToolbar={handleShowToolbar}
        hasBeenSigned={hasBeenSigned}
        isDateObject={isDateObject}
        isSignatureObject={isSignatureObject}
        isTextField={isTextField}
        isTemplateMode={isTemplateMode}
        markFieldAsSigned={markFieldAsSigned}
        openSignatureEntryModal={openSignatureEntryModal}
        fieldRef={fieldRef}
        resizeHandleRef={resizeHandleRef}
        showFieldHeader={showFieldHeader}
        signerFieldProps={signerFieldProps}
        signingLocationsAreDraggable={signingLocationsAreDraggable}
        signingObject={signingObject}
        updateSigningObject={updateSigningObject}
      />
      <CpContextDropdown
        ref={dropdownRef}
        contentWidth="auto"
        position="top-start"
        allowContentClicks={true}
        isOpen={isActive}
        onClose={() => {
          setShowFieldHeader(true);
        }}
        renderContent={({ close }) => (
          <div {...scope} className="toolbar-styling" ref={toolbarRef}>
            <SignerName
              isDragging={isDragging}
              setSigner={setSigner}
              signerFieldProps={signerFieldProps}
              signingLocationsAreDraggable={signingLocationsAreDraggable}
              signingObject={signingObject}
            />
            <SignerToolbarButtons
              buttonsContainerRef={buttonsContainerRef}
              duplicateSigningField={duplicateSigningField}
              deleteField={deleteField}
              signatureElements={hasEsignImprovementsBeta ? signatureElementsBeta : signatureElements}
              signerFieldProps={signerFieldProps}
              signingObject={signingObject}
              updateSigningObjectFieldType={updateSigningObjectFieldType}
            />
          </div>
        )}
      />
    </div>
  );
}

const css = k`
  .toolbar-styling {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    padding: 8px 8px 8px 0;
    width: fit-content;
    height: 40px;
    background: var(--cp-color-toast-bg);
    border-radius: 5px;
    white-space: nowrap;
  }
`;
