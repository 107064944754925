import React, { useEffect, useRef, useContext } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { featureEnabled } from "feature-toggles!sofe";
import { dragTypes } from "../constants.js";
import { SigningContext } from "../signing-context";
import { userMatchesSigningLocation } from "../signing-modal.helper";

export const DragWrapper = ({ signingObject, clientCollaborators, allUsers, canDrag, ...props }) => {
  const client = allUsers.find((user) => userMatchesSigningLocation(signingObject, user));
  //TODO: do we need the clientCollaborators[signingObject.signatory_user_id] check?
  const clientName = client?.name || clientCollaborators[signingObject.signatory_user_id];
  const resizeHandleRef = useRef(null);
  const { activeSignerFieldId, hasEsignImprovementsBeta } = useContext(SigningContext);

  const resizableField =
    featureEnabled("toggle_files_esign_improvements") &&
    hasEsignImprovementsBeta &&
    !!signingObject.width &&
    !!signingObject.height;

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: dragTypes.SIGNING_FIELD,
      item: () => {
        return { ...signingObject, name: clientName };
      },
      canDrag: canDrag,
      isDragging: (monitor) => {
        return monitor.getItem()?.id === signingObject.id;
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [signingObject, canDrag, clientName]
  );

  useEffect(() => {
    if ((!signingObject.value && !signingObject.completed_at) || resizableField) {
      preview(getEmptyImage(), {
        captureDraggingState: true,
      });
    } else {
      preview();
    }
  }, [preview, signingObject, canDrag]);

  const dragStyles = {
    marginTop: signingObject.y,
    marginLeft: signingObject.x,
    cursor: canDrag ? "move" : null,
    position: "absolute",
    zIndex: activeSignerFieldId === (signingObject.esigning_location_id || signingObject.id) ? 11000 : 10000,
  };

  return (
    <div
      ref={canDrag ? drag : signingObject.ref}
      style={dragStyles}
      onMouseDown={(e) => {
        if (featureEnabled("toggle_files_esign_improvements") && hasEsignImprovementsBeta) {
          const isResizeHandle = e.target.closest(".react-resizable-handle");
          if (isResizeHandle) {
            e.stopPropagation();
          }
        }
      }}
    >
      {React.cloneElement(props.children, {
        isDragging,
        resizeHandleRef,
      })}
    </div>
  );
};
