import React from "react";
import { useCss, k, a, m } from "kremling";
import { CpIcon, CpTooltip } from "canopy-styleguide!sofe";

export const DraggableResizableFieldHeader = ({ handleClickShowToolbar, signerFieldProps, size, isTeamMember }) => {
  const scope = useCss(css);

  return (
    <div
      {...scope}
      className={a("custom-resizable-box-header").t(
        "header-background-teamMember",
        "header-background-client",
        isTeamMember
      )}
      onClick={handleClickShowToolbar}
      style={{ maxWidth: `${size.width}px` }}
    >
      <CpTooltip text={signerFieldProps?.text}>
        <div className="header-content">
          {signerFieldProps?.icon && <CpIcon name={signerFieldProps.icon} />}
          <span className="header-text">{signerFieldProps?.text}</span>
        </div>
      </CpTooltip>
    </div>
  );
};

const css = k`
  .custom-resizable-box-header {
    display: flex;
    position: absolute;
    align-items: center;
    top: -22px;
    left: 0;
    width: fit-content;
    height: 22px;
    color: var(--cp-color-app-primary-dark-text);
    padding: 2px 4px;
    gap: 4px;
    border-radius: 8px 8px 0 0;
    z-index: 10;
    cursor: pointer;
  }

  .header-content {
    display: flex;
    align-items: center;
    min-width: 0;  
    flex: 1;
  }

  .header-text {
    font-size: 10px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    margin-left: 4px;
  }

  .header-background-teamMember {
    background-color: var(--cp-color-pill-team-text);
  }

  .header-background-client {
    background-color: var(--cp-color-default-badge-bg);
  }
`;
